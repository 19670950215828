@import url('https://fonts.googleapis.com/css2?family=Bungee+Shade&display=swap');

#player {
  width: auto;
}

.containerVideo {
  position: relative;
  /*height: 90%;
  width: 90%;*/
  height: calc(var(--vh, 1vh)* 100);
  border-radius: unset;
  box-shadow: unset;
  width: 100vw;
  display: flex;
  margin: auto;
} 

.videoHTML  {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: contain;
}

.fullscreenButton {
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  cursor: pointer;
}

.backButton {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px 20px;
  background: transparent;
  color: white;
  border: none;
  cursor: pointer;
  z-index: 10;
}

.backButton img {
  width: 30px;
  height: 30px;
  display: block;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Fallback */
  height: calc(var(--vh, 1vh) * 100);
  text-align: center;
  touch-action:pan-y;
}

.homeDiv {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.logo {
  width: 500px;
  top: 20%;
  position: relative;
}

.homeButton {
  width:300px;
}


.holderGame {
  /*width: 100%;*/
  position: relative;
  background: black;
  justify-content: center;
}

.gameBar {
  position: absolute;
  background: transparent;
  z-index: 2;
  bottom: 0;
  right: 0;
  height: 40px;
}

.quitButton {
  width:40%;
  margin-top:50px;
}

.gameFrame{
  width: 100%;
  height: 100%;
}


.slogan {
  font-family: 'Bungee Shade', cursive;
  color:rgb(140, 161, 180);
}

.rotatingRecord {
  animation: spin 5s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.dblClickHeart {
  height: 80px;
  width: 80px;
  margin-left: -40px;
  margin-top: -40px;
  opacity: 0;
}

.dblClickHeart.is-active {
  animation: popUpHeart 400ms linear;
  animation-fill-mode: forwards;
}

@keyframes popUpHeart {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(3);
  }
}

.playButton {
  width: 70px;
}

.Screen {
  height: 800px;
  width: 450px;
  background-color: #000;
  overflow: hidden;
  user-select: none;
  border-radius: 20px;
  box-shadow: 2px 2px 120px rgba(255, 255, 255, 0.15);
}

#helpDiv img {
  position: absolute;
  z-index: 1;
  width:400px;
  left: 0; 
  right: 0; 
  top:20%;
  margin-left: auto; 
  margin-right: auto; 
}


@media (max-width: 650px) {
  .Screen {
    border-radius: unset;
    box-shadow: unset;
    width: 100vw;
    height: 100vh; /* Fallback */
    height: calc(var(--vh, 1vh) * 100);
  }

  .logo {
    width: 100%;
    top: 20%;
    position: relative;
  }
  
  .homeButton {
    width:60%;
  }

  #helpDiv img {
    width:250px;
  }
}

.fade-in {
  visibility: visible;
  opacity: 0.90;
  transition: opacity 2s linear;
}

.nada {
  visibility: hidden;
}

.fade-out {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s linear;
}



/** swiper **/

.swiper {
  /*width: 100%;
  height: 100%;*/
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  object-fit: cover;
}